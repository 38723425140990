<template>
    <div>
        <div class="bg-gray-50 p-4 mb-6">
            <div class="form-row">
                <div class="form-col">
                    <label for="title">page title</label>
                    <text-input
                      id="title"
                      v-model="pageObj.website_pages_name"
                      autocomplete="off"
                    />
                    <span v-if="hasError('pageObj.website_pages_name')" class="form-error">{{ showError('pageObj.website_pages_name') }}</span>
                    <span class="form-hint" v-if="!hasError('pageObj.website_pages_name')">This is the name that will appear in the navigation menu.</span>
                </div>
                <div class="form-col">
                    <label for="slug">url alias</label>
                    <text-input
                      id="slug"
                      inputClass="bg-transparent border-transparent"
                      v-model="pageObj.url_alias"
                      autocomplete="off"
                    />
                    <span v-if="hasError('pageObj.url_alias')" class="form-error">{{ showError('pageObj.url_alias') }}</span>
                    <span class="form-hint">This is the slug that the page will use.</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <p class="font-bold text-xs mb-2">show in navigation?</p>
                    <toggle-input v-model="pageObj.show_in_nav" />
                </div>
                <div class="form-col">
                    <p class="font-bold text-xs mb-2">set as homepage?</p>
                    <toggle-input v-model="pageObj.is_home_page" />
                </div>
                <div class="form-col">
                    <p class="font-bold text-xs mb-2">publish page?</p>
                    <toggle-input v-model="pageObj.is_published" />
                </div>
            </div>
            <div class="form-row border-t pt-8">
                <div class="form-col">
                    <div>
                        <label for="seo_title">seo page title</label>
                        <text-input
                          id="seo_title"
                          v-model="pageObj.seo_title"
                          autocomplete="off"
                        />
                        <span class="form-hint">This is used in the meta tags and should be less than 60 characters.</span>
                    </div>
                    <div class="mt-8">
                        <label>social sharing image</label>
                            <file-manager
                                :main-directory="'cms'"
                                :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                                :libraryFilters="['marketing', 'cms']"
                                :max-upload-size="20"
                                :max-nb-of-files="1"
                                :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
                                v-model="seo_image"
                            ></file-manager>
                        <span class="form-hint pt-2">This image is used on social platforms when the page link is shared.</span>
                    </div>
                </div>
                <div class="form-col">
                    <label for="seo_description">seo page description</label>
                    <text-input
                      id="seo_description"
                      inputClass="h-56"
                      v-model="pageObj.seo_description"
                      multiline
                    />
                    <span class="form-hint">This is used in the meta tags and should be less than 160 characters.</span>
                </div>
            </div>
        </div>

        <modal-footer :primary="save" :secondary="close" :footer="footer"></modal-footer>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import ToggleInput from "@/components/ui/ToggleInput";
    import {slugify} from "@/utils/String";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation, Auth ],
        components: { ModalFooter, ToggleInput, FileManager, TextInput },
        props: {
            page: {
                type: Object,
                required: true,
            },
            update: {
                type: Boolean,
                default: false,
            }
        },
        data: () => {
            return {
                website: {},
                pageObj: {},
                seo_image: [],
                isProcessing: false,
                footer: {
                    primaryButton: 'Save',
                    secondaryButton: 'Cancel'
                }
            }
        },
        watch: {
            'page': {
                handler: function() {
                    this.pageObj = Object.assign({}, this.page);
                    this.pageObj.originalPageName = this.page.website_pages_name;

                    if (this.pageObj.seo_image_url) {
                        let fileId = this.getFileIdByThumbnailUrl(this.pageObj.seo_image_url);
                        this.seo_image = [fileId];
                    }
                },
                deep: true,
                immediate: true
            },
            'pageObj': {
                handler: function() {
                    this.pageObj.url_alias = slugify(this.pageObj.website_pages_name);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl',
                getPagesByWebsite: 'pages/getPagesByWebsite',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                addPageToStore: 'pages/createPage',
                updatePageInStore: 'pages/updatePage',
                notifyError: 'alerts/notifyError',
            }),
            save() {
                this.pageObj.seo_image_url = (this.seo_image.length) ? this.getThumbnailUrlByFileId(this.seo_image[0]) : null;
                (this.update) ? this.updatePage() : this.createPage();
            },
            createPage() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    this.$cmsDataProvider.create('createPage', {
                        websiteId: this.$route.params.websiteId,
                        data: this.pageObj
                    })
                        .then((response) => {
                            this.isProcessing = false;
                            this.processSave(response);
                        }).catch(() => {
                        this.isProcessing = false;
                        this.notifyError('There was an error saving the information');
                    });
                }
            },
            updatePage() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let params = {
                        websiteId: this.pageObj.websites_id,
                        id: this.pageObj.originalPageName,
                        data: this.pageObj
                    };

                    this.$cmsDataProvider.update('updatePage', params)
                        .then((response) => {
                            this.isProcessing = false;
                            this.processSave(response);
                        }).catch(() => {
                        this.isProcessing = false;
                        this.notifyError('There was an error saving the information');
                    });
                }
            },
            processSave(data) {
                if(this.validator.passes()) {
                    this.pageObj = data;
                    this.pageObj.originalPageName = this.page.website_pages_name;
                    this.update ? this.updatePageInStore(this.pageObj): this.addPageToStore(this.pageObj);
                    this.isProcessing = false;
                    this.redirect('pages.show', {
                        websiteId: this.pageObj.websites_id,
                        pageSlug: this.pageObj.url_alias
                    });
                }
            }
        },
        validations: {
            'pageObj.website_pages_name': 'required',
            'pageObj.url_alias': 'required | unique_website_page_alias',
        },
        created() {
            this.initValidator();
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.pageObj.communities_id = this.website.communities_id
            this.pageObj.websites_id = this.website.id
            this.validator.extend('unique_website_page_alias', (value) => {
                // if the page name has not changed, ignore this rule
                if(this.pageObj.website_pages_name === this.page.website_pages_name) {
                    return true;
                }

                let websitePages = this.getPagesByWebsite(this.page.websites_id);
                if(!websitePages.length)
                    return true;

                let page = websitePages.find( p => p.url_alias === value);
                return !page;
            });

            this.validator.setCustomErrorMessages({
                'pageObj.url_alias.unique_website_page_alias' : 'the page name and alias already exist for this website.'
            });
        }
    }
</script>

<style>
    #slug{
        padding-left: 0 !important;
    }
</style>
